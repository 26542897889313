import * as React from "react";
import Layout from '../components/layout';

const NotFoundPage = ({ location }) => { 

  return (
    <Layout data-location={location} pageTitle={'404 - Ideas Report Hub | WeTransfer'}>
      <h1>404</h1>
  </Layout>
)}

export default NotFoundPage;